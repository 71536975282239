import { createReducer, on } from '@ngrx/store';
import { CoreState, HeaderConfig, initialState } from './core.interface';
import * as CoreActions from './core.actions';
import { loaderReducerState } from '@core/utils';
import { BtnclickStatus } from '@core/directives/btn-with-spinner.directive';

export const coreReducer = createReducer(
  initialState,
  on(CoreActions.getFinancialInsitutions, (state) => ({
    ...state,
    financialInstitutions: loaderReducerState(null, 'get'),
  })),
  on(CoreActions.getFinancialInsitutionsSuccess, (state, { financialInstitutions }) => ({
    ...state,
    financialInstitutions: loaderReducerState(financialInstitutions, 'success'),
  })),
  on(CoreActions.getFinancialInsitutionsFailed, (state, { error }) => ({
    ...state,
    financialInstitutions: loaderReducerState(null, 'error', error),
  })),
  on(
    CoreActions.setHeaderConfig,
    (state, { headerConfig }): CoreState => ({
      ...state,
      headerConfig: {
        closeButton: false,
        ...(headerConfig as HeaderConfig),
      },
    })
  ),
  on(
    CoreActions.setFooterConfig,
    (state, { footerConfig }): CoreState => ({
      ...state,
      footerConfig: {
        ...state.footerConfig,
        ...footerConfig,
      },
    })
  ),
  on(
    CoreActions.setFilteredAccountDisableReasons,
    (state, { filteredAccountDisableReasons }): CoreState => ({
      ...state,
      filteredAccountDisableReasons,
    })
  ),
  on(
    CoreActions.setMerchantReferrer,
    (state, { merchantReferrer }): CoreState => ({
      ...state,
      merchantReferrer,
    })
  ),
  on(
    CoreActions.selectFI,
    (state, { selectedFI }): CoreState => ({
      ...state,
      ...(selectedFI ? { selectedFI } : {}),
    })
  ),
  on(
    CoreActions.setOIDCError,
    (state, { OIDCError }): CoreState => ({
      ...state,
      OIDCError,
    })
  ),
  on(
    CoreActions.clearOIDCError,
    (state): CoreState => ({
      ...state,
      OIDCError: undefined,
    })
  ),
  on(
    CoreActions.setMaxShippingAddress,
    (state, { maxShippingAddress }): CoreState => ({
      ...state,
      maxShippingAddress,
    })
  ),
  on(
    CoreActions.setOIDCCode,
    (state, { OIDCCode }): CoreState => ({
      ...state,
      OIDCCode,
    })
  ),
  on(
    CoreActions.setOIDCCode,
    (state, { OIDCCode }): CoreState => ({
      ...state,
      OIDCCode,
    })
  ),
  on(
    CoreActions.setTempEmail,
    (state, { tempemail }): CoreState => ({
      ...state,
      tempemail,
    })
  ),
  on(
    CoreActions.hydrateCore,
    (state, { tempemail, OIDCCode }): CoreState => ({
      ...state,
      tempemail,
      OIDCCode,
    })
  ),
  on(CoreActions.attachDevice, (state) => ({
    ...state,
    attachDeviceApi: loaderReducerState(BtnclickStatus.Pending, 'start'),
  })),
  on(
    CoreActions.clearDeviceId,
    (state): CoreState => ({
      ...state,
      deviceId: '',
    })
  ),
  on(CoreActions.attachDeviceSuccess, (state, { deviceId }) => ({
    ...state,
    attachDeviceApi: loaderReducerState(BtnclickStatus.Success, 'success'),
    deviceId,
  })),
  on(CoreActions.attachDeviceFailed, (state, { error }) => ({
    ...state,
    attachDeviceApi: loaderReducerState(BtnclickStatus.Failed, 'error', error),
  })),

  on(CoreActions.updateDevice, (state) => ({
    ...state,
    updateDeviceApi: loaderReducerState(BtnclickStatus.Pending, 'start'),
  })),
  on(CoreActions.updateDeviceSuccess, (state) => ({
    ...state,
    updateDeviceApi: loaderReducerState(BtnclickStatus.Success, 'success'),
  })),
  on(CoreActions.updateDeviceFailed, (state, { error }) => ({
    ...state,
    updateDeviceApi: loaderReducerState(BtnclickStatus.Failed, 'error', error),
  }))
);

export const reducers = {
  core: coreReducer,
};
