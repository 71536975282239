import { Inject, Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler } from '@angular/common/http';
import { catchError, Observable, retryWhen } from 'rxjs';
import { getApiType, genericRetryStrategy, isAutoRedirectError, isAuthError } from './helper';
import { Router } from '@angular/router';
import { APP_CONFIG } from '@core/interfaces/configs';
import { APPCONFIG } from '@core/tokens/configs';
import { isCheckout, setCookie } from '@core/utils';
import { CNAME } from '@core/constants/cookies';
import { SNAME } from '@core/constants/storage';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(@Inject(APPCONFIG) private readonly appConfig: APP_CONFIG, private readonly router: Router) {}
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const attempts = this.getRetryAttempts(httpRequest);
    const duration = this.getRetryDuration(httpRequest);
    return next.handle(httpRequest).pipe(
      retryWhen(genericRetryStrategy(attempts, duration)),
      catchError((err) => {
        if (isAutoRedirectError(err, httpRequest.method)) {
          this.router.navigate([`/${isCheckout() ? 'checkout' : 'user-portal'}/error`]);
        }
        if (isAuthError(err)) {
          setCookie(CNAME.PBB_UI_TOKEN, null, -1);
          sessionStorage.setItem(SNAME.FORCE_SIGNOUT, 'true');
          this.router.navigate([`/${isCheckout() ? 'checkout' : 'user-portal'}`]);
        }
        throw err;
      })
    );
  }

  getRetryAttempts(req: HttpRequest<any>) {
    const configKey = `${getApiType(req)}RetryAttempts` as keyof APP_CONFIG;
    return (this.appConfig[configKey] as number) || this.appConfig.globalRetryAttempts;
  }

  getRetryDuration(req: HttpRequest<any>) {
    const configKey = `${getApiType(req)}RetryDuration` as keyof APP_CONFIG;
    return (this.appConfig[configKey] as number) || this.appConfig.globalRetryDuration;
  }
}
