import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRoute,
  GuardsCheckEnd,
  GuardsCheckStart,
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { UntilDestroy } from '@core/classes/untilDestroy.class';
import { SNAME } from '@core/constants/storage';
import { APP_CONFIG, ENV_CONFIG } from '@core/interfaces/configs';
import { FormStateService } from '@core/services/form-state.service';
import { LoggerService } from '@core/services/logger.service';
import { RouterService } from '@core/services/router.service';
import { TmxService } from '@core/services/tmx.service';
import { WindowService } from '@core/services/window.service';
import { APPCONFIG, ENVCONFIG, TMX } from '@core/tokens/configs';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { getFinancialInsitutions, setHeaderConfig } from '@store/core/core.actions';
import { HeaderConfig } from '@store/core/core.interface';
import { Observable, distinctUntilChanged, filter, map, takeUntil } from 'rxjs';
import { v4 } from 'uuid';
import { FooterComponent } from './core/components/footer/footer.component';
import { PbbSvgIconComponent } from './core/components/pbb-svg-icon/pbb-svg-icon.component';
const { version, apiVersion } = require('../../package.json');

@Component({
  selector: 'pbb-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LetDirective,
    NgIf,
    MatProgressSpinnerModule,
    PbbSvgIconComponent,
    RouterOutlet,
    FooterComponent,
    TranslateModule,
  ],
})
export class AppComponent extends UntilDestroy implements OnInit {
  title = 'konek-app';
  hideFooter: boolean = false;
  showSpinner$: Observable<boolean | undefined> = this.router.events.pipe(
    filter(
      (e) =>
        e instanceof GuardsCheckStart ||
        e instanceof NavigationStart ||
        e instanceof GuardsCheckEnd ||
        e instanceof NavigationCancel ||
        e instanceof NavigationEnd
    ),
    map((e) => {
      if (this.formStateService.hasChange) {
        return false;
      }
      if (e instanceof GuardsCheckStart || e instanceof NavigationStart) {
        return true;
      }
      if (e instanceof GuardsCheckEnd || e instanceof NavigationCancel || e instanceof NavigationEnd) {
        return false;
      }
      this.cd.markForCheck();
      return false;
    }),
    distinctUntilChanged(),
    takeUntil(this.destroy$)
  );

  @HostListener('window:popstate', ['$event'])
  onBack(event: PopStateEvent) {
    const targetPath = location.pathname;
    if (!this.routerService.enableBack) {
      history.pushState(null, 'null', location.href);
      history.go(1);
    } else {
      this.router
        .navigate([targetPath], {
          replaceUrl: true,
        })
        .then((res) => {
          if (targetPath !== location.pathname) {
            location.replace(targetPath);
          }
        });
    }
  }

  constructor(
    private readonly router: Router,
    private readonly cd: ChangeDetectorRef,
    private readonly windowService: WindowService,
    private readonly routerService: RouterService,
    private readonly store: Store,
    private readonly titleService: Title,
    private readonly translateService: TranslateService,
    @Inject(ENVCONFIG) private readonly environment: ENV_CONFIG,
    @Inject(APPCONFIG) private readonly appConfig: APP_CONFIG,
    @Inject(TMX) private readonly tmxService: TmxService,
    public readonly logger: LoggerService,
    private readonly formStateService: FormStateService
  ) {
    super();
    router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.router.routerState.root;
          let routeTitleKey = '';

          while (route.firstChild) {
            route = route.firstChild;
          }
          if (route.snapshot.data['pageTitleKey']) {
            routeTitleKey = route.snapshot.data['pageTitleKey'];
          }
          this.logger.log('NAVIGATION', 'TITLE', this.translateService.instant(routeTitleKey));
          return this.translateService.instant(routeTitleKey);
        })
      )
      .subscribe((title: string) => {
        if (title) {
          this.titleService.setTitle(`KONEK - ${title}`);
        }
      });

    // generate correlationId for standalone routes
    if (!this.isCheckoutRoute()) {
      sessionStorage.setItem(SNAME.CORRELATIONID, v4());
    }

    if (!this.isStandAlonePageRoute()) {
      this.store.dispatch(getFinancialInsitutions());
    }
  }
  
  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent) {
    if (this.isCheckoutRoute()) {
      this.windowService.fromMerchant(event);
    }

    if (typeof event.data === 'string' && event.data.includes('tmx')) {
      this.tmxService.messageRecevied(event.data);
    }
  }

  isCheckoutRoute(): boolean {
    return (location.href || '').includes('checkout');
  }

  isStandAlonePageRoute(): boolean {
    const pageRoutes = ['email-verification', 'pilot'];
    return pageRoutes.some((route) => (location.href || '').includes(route));
  }
  getLocation() {
    return location.href;
  }
  ngOnInit(): void {
    this.logger.log('VERSION', 'APP', version);
    this.logger.log('VERSION', 'SPEC', apiVersion);
    this.logger.log('CONFIG', 'ENV', this.environment);
    this.logger.log('CONFIG', 'APP', this.appConfig);

    this.routerService.routeData.pipe(takeUntil(this.destroy$)).subscribe((headerConfig: HeaderConfig) => {
      this.hideFooter = headerConfig.hideFooter ? headerConfig.hideFooter : false;
      this.store.dispatch(
        setHeaderConfig({
          headerConfig,
        })
      );
    });
  }
}
