import { APP_CONFIG } from '@core/interfaces/configs';

export const CONFIG: APP_CONFIG = {
  fiRedirectTimer: 2000,
  fiRedirectStepupTimer: 2000,
  cancelTimer: 2000,
  authorizeRedirectTimer: 2000,
  globalRetryAttempts: 3,
  globalRetryDuration: 500,
  globalTimeoutPeriod: 10000,
  getConsentRetryAttempts: 3,
  getConsentTimeoutPeriod: 5000,
  grantRetryAttempts: 3,
  grantTimeoutPeriod: 5000,
  filteredAccountDisableReasons: [],
  getShippingCostTimeoutPeriod: 2000,
  failedShippingCostTimer: 5000,
  signOutTimer: 1000 * 60 * 20, // 20Mins
  skipWebAuthnTrustLimit: 3,
  registerRetryAttempts: 3,
  registerRetryDuration: 1000,
  getConsentTokenizedRetryAttempts: 6,
  getConsentTokenizedRetryDuration: 500,
  verifyEmailTimeoutPeriod: 2000,
  verifyEmailRetryAttempts: 3,
  verifyEmailRetryDuration: 2000,
  profileLookupRetryAttempts: 1,
  newlyAddedLimit: 1 * 24 * 60,
  enableWebauthn: true,
  linkedFiLimitForAccordionView: 2,

  sessionTimeout: 1000 * 60 * 2,
  sessionWarningPeriod: 1000 * 30,
  webAuthnVerifyRetryAttempts: 2,
  webAuthnVerifyRetryDuration: 2000,
  webAuthnSessionRetryAttempts: 2,
  webAuthnSessionRetryDuration: 2000,
  webauthnAuthenticateRetryAttempts: 3,
  tmxGrantTimeout: 5000,
  hBReactionBuffer: 5000,
  keepAliveTimer: 2000,
  noticeTimeout: 0,
  enableOTC: true,
  splashTimeout: 3000,
};
