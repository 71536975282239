import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, timeout } from 'rxjs';
import { APP_CONFIG } from '@core/interfaces/configs';
import { APPCONFIG } from '@core/tokens/configs';
import { getApiType } from './helper';

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
  constructor(@Inject(APPCONFIG) private readonly appConfig: APP_CONFIG) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(timeout(this.getTimeoutPeriod(request)));
  }

  getTimeoutPeriod(req: HttpRequest<any>): number {
    // getConsent
    const configKey = `${getApiType(req)}TimeoutPeriod` as keyof APP_CONFIG;
    return (this.appConfig[configKey] as number) || this.appConfig.globalTimeoutPeriod;
  }
}
