import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { SNAME } from '@core/constants/storage';
import { APP_CONFIG, ENV_CONFIG } from '@core/interfaces/configs';
import { APPCONFIG, ENVCONFIG } from '@core/tokens/configs';
import { BehaviorSubject, filter, take, timeout } from 'rxjs';
import { LoggerService } from './logger.service';
declare global {
  interface Window {
    pbbTmx: any;
    tmx_tags_started: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class TmxService {
  pbbTmx: any;
  pageID = 'PBBUI';
  private sessionId?: string;
  private sessionId$ = new BehaviorSubject('');
  private profilingInProgress = false;
  public tmxProfilingComplete$ = this.sessionId$.asObservable().pipe(
    filter((sessionId) => !!sessionId),
    timeout({
      first: this.appConfig.tmxGrantTimeout,
      with: () => {
        if (this.sessionId) {
          sessionStorage.setItem(SNAME.TMX_SESSION_ID, this.sessionId);
          this.sessionId$.next(this.sessionId);
        }
        return this.sessionId$;
      },
    }),
    take(1)
  );

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(ENVCONFIG) private readonly envConfig: ENV_CONFIG,
    @Inject(APPCONFIG) private readonly appConfig: APP_CONFIG,
    private readonly logger: LoggerService
  ) {}

  init() {
    this.logger.log('TMX', 'INIT');

    return this;
  }

  public startProfiling(sessionId: string) {
    if (!this.envConfig.enableTmx) {
      this.logger.log('TMX', 'skipped for this environment');
      this.sessionId$.next(sessionId);
      return;
    }
    this.sessionId = this.getSessionId();
    if (this.sessionId) {
      this.logger.log('TMX', 'EXISTING SESSIONID', this.sessionId);
      this.sessionId$.next(this.sessionId);
      return;
    }
    if (!this.sessionId && !this.profilingInProgress) {
      console.time('[TMX] SDK LOADED');
      console.time('[TMX] PROFILING STARTED');
      console.time('[TMX] PROFILING COMPLETED');
      this.loadTMXScript(sessionId);
    }
  }

  public loadTMXScript(sessionId: string) {
    this.sessionId = sessionId;
    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.src = this.envConfig.tmxSDKSrc;
    script.onload = this.onScriptLoad.bind(this);
    this.document.head.appendChild(script);
    this.profilingInProgress = true;
  }

  onScriptLoad() {
    console.timeEnd('[TMX] SDK LOADED');

    const noscript = this.document.createElement('noscript');
    const iframe = this.document.createElement('iframe');
    iframe.style.width = '100px';
    iframe.style.height = '100px';
    iframe.style.border = '0';
    iframe.style.position = 'absolute';
    iframe.style.top = '-5000px';
    iframe.src = `${this.envConfig.tmxScriptSrc}/fp/tags?org_id=${this.envConfig.tmxOrgId}&session_id=${this.sessionId}`;
    noscript.appendChild(iframe);
    this.document.body.appendChild(noscript);
    window?.tmx_tags_started((profileStarted: boolean) => {
      console.timeEnd('[TMX] PROFILING STARTED');
    });
  }

  messageRecevied(msg: string) {
    console.timeEnd('[TMX] PROFILING COMPLETED');
    if (this.sessionId) {
      sessionStorage.setItem(SNAME.TMX_SESSION_ID, this.sessionId);
      this.logger.log('TMX', 'SESSIONID', this.sessionId);
      this.profilingInProgress = false;
      this.sessionId$.next(this.sessionId);
    } else {
      this.logger.error('TMX', 'Something went wrong');
    }
  }

  getSessionId(): string | undefined {
    const sessionID = sessionStorage.getItem(SNAME.TMX_SESSION_ID);
    if (sessionID) {
      return sessionID;
    }
    return undefined;
  }
}
