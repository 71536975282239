<!-- PAGE FOOTER -->
<div *ngIf="!isPilot" class="content_main-footer-page mat-body-2">
  <div class="flex-row">
    <a href="{{ 'FOOTER.LINKS.PBB.URL' | translate }}" [attr.aria-label]="'ARIA_LABEL.PAY_BY_BANK_LOGO' | translate" class="link-text footer-logo" target="_blank" rel="noopener">
      <pbb-svg-icon [icon]="'konek-logo'" [title]="'COMMON.LOGO_NAME'| translate" width="32px" height="32px" class="icon-md"></pbb-svg-icon>
      <div class="footer-logo-text">
        <span class="mat-body-2 mr-4 text-dark-charcoal"> {{ 'FOOTER.LINKS.PBB.LINK' | translate }}</span>
        <mat-icon class="icon-sm" color="primary">launch_outlined</mat-icon>
      </div>
    </a>
    <pbb-lang-toggle></pbb-lang-toggle>
  </div>
  <ul class="footer-link-list">
    <li class="footer-links" *ngFor="let item of footerLinks; let i = index">
      <a id="footer-link-{{ i }}" target="_blank" class="link-text-footer" href="{{ item.url | translate }}">
        {{ item.link | translate }}
      </a>
    </li>
  </ul>
</div>
