import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs';
import { getCookie } from '@core/utils';
import { CNAME } from '@core/constants/cookies';
@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      httpRequest.headers.get('Authorization') ||
      (httpRequest.url.includes('financial-institution') && httpRequest.method === 'GET') ||
      httpRequest.url.includes('pilot-otc')
    ) {
      return next.handle(httpRequest);
    }
    return next.handle(
      httpRequest.clone({
        setHeaders: {
          Authorization: `Bearer ${getCookie(CNAME.PBB_UI_TOKEN) || ''}`,
        },
      })
    );
  }
}
