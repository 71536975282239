import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  setState(state: any, localStorageKey: string) {
    localStorage.setItem(localStorageKey, JSON.stringify(state));
  }

  getState(localStorageKey: string): any {
    try {
      return JSON.parse(localStorage.getItem(localStorageKey) || '');
    } catch (err) {
      return null;
    }
  }
}
