import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs';
import { v4 } from 'uuid';
import { PBBHTTPHeaders } from '@core/constants/http-headers';
import { SNAME } from '@core/constants/storage';
import { TranslateService } from '@ngx-translate/core';
import { Languages } from '@core/services/language.service';
@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(private readonly translateService: TranslateService) {}
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(
      httpRequest.clone({
        setHeaders: {
          [PBBHTTPHeaders.requestId]: v4(),
          [PBBHTTPHeaders.correlationId]: sessionStorage.getItem(SNAME.CORRELATIONID) || v4(),
          [PBBHTTPHeaders.accentlanguage]: this.translateService?.currentLang || Languages.ENGLISH,
        },
      })
    );
  }
}
