import { enableProdMode, APP_INITIALIZER, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { APP_CONFIG, ENV_CONFIG } from '@core/interfaces/configs';
import { APPCONFIG, ENVCONFIG, TMX } from '@core/tokens/configs';
import { LoggerService } from '@core/services/logger.service';

import { CONFIG } from './app/core/constants/config';
import { AppComponent } from './app/app.component';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { ApiModule as CoreApiModule } from '@api/api.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CoreEffects } from '@store/core/core.effects';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from '@store/core/core.reducer';
import { StoreModule } from '@ngrx/store';
import { AppRoutingModule } from './app/routes/app-routing.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { Configuration } from '@api/configuration';
import { HeaderInterceptor } from '@core/interceptors/header.interceptor';
import { TimeoutInterceptor } from '@core/interceptors/timeout.interceptor';
import { Router } from '@angular/router';
import { ErrorInterceptor, AuthHeaderInterceptor } from '@core/interceptors';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient, HttpClient } from '@angular/common/http';
import { LanguageService } from '@core/services/language.service';
import { TranslateService, TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { initializeLang, gtmConfigFactory, apiConfigFactory, HttpLoaderFactory } from '@core/factories';
import { MatDialogModule } from '@angular/material/dialog';
import { UtilService } from '@core/services/util.service';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { TmxService } from '@core/services/tmx.service';
import { LocalStorageService } from '@core/metareducers/local-storage.service';
import { NgxMaskService, provideNgxMask } from 'ngx-mask';
import frCA from '@angular/common/locales/fr-CA';
import { registerLocaleData } from '@angular/common';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { CookieAcceptService } from '@cookie/cookie-accept.service';
fetch(`/env.json?${new Date().getTime()}`)
  .then((res) => res.json())
  .then((envConfig: ENV_CONFIG) => {
    fetch(`/assets/app-config.json?${new Date().getTime()}`)
      .then((res) => res.json())
      .then((appConfig: APP_CONFIG) => {
        if (envConfig.production) {
          enableProdMode();
        }
        registerLocaleData(frCA);
        return bootstrapApplication(AppComponent, {
          providers: [
            importProvidersFrom(
              BrowserModule,
              MatDialogModule,
              MatBottomSheetModule,

              AppRoutingModule,
              TranslateModule.forRoot({
                loader: {
                  provide: TranslateLoader,
                  useFactory: HttpLoaderFactory,
                  deps: [HttpClient],
                },
              }),
              StoreModule.forRoot(reducers),
              EffectsModule.forRoot([CoreEffects]),
              StoreDevtoolsModule.instrument({
                maxAge: 25,
                logOnly: true,
                autoPause: true, // Pauses recording actions and state changes when the extension window is not open
              }),
              CoreApiModule,
              GoogleTagManagerModule.forRoot()
            ),
            { provide: ENVCONFIG, useValue: envConfig },
            {
              provide: APPCONFIG,
              useValue: {
                ...CONFIG,
                ...appConfig,
              },
            },
            {
              provide: TMX,
              useValue: new TmxService(document, envConfig, appConfig, new LoggerService(envConfig)).init(),
            },
            {
              provide: APP_INITIALIZER,
              useFactory: initializeLang,
              deps: [TranslateService, LanguageService, LocalStorageService],
              multi: true,
            },
            {
              provide: HTTP_INTERCEPTORS,
              useClass: ErrorInterceptor,
              multi: true,
              deps: [APPCONFIG, Router],
            },
            {
              provide: HTTP_INTERCEPTORS,
              useClass: AuthHeaderInterceptor,
              multi: true,
              deps: [],
            },
            {
              provide: HTTP_INTERCEPTORS,
              useClass: TimeoutInterceptor,
              multi: true,
              deps: [APPCONFIG],
            },
            {
              provide: HTTP_INTERCEPTORS,
              useClass: HeaderInterceptor,
              multi: true,
              deps: [TranslateService],
            },
            {
              provide: Window,
              useValue: window,
            },
            {
              provide: Document,
              useValue: document,
            },
            {
              provide: LOCALE_ID,
              deps: [TranslateService],
              useFactory: (translateService: TranslateService) => translateService.currentLang,
            },
            {
              provide: 'googleTagManagerId',
              useFactory: gtmConfigFactory,
              deps: [ENVCONFIG],
            },
            { provide: Configuration, useFactory: apiConfigFactory, deps: [ENVCONFIG] },
            UtilService,
            TranslateService,
            NgxMaskService,
            provideNgxMask(),
            provideAnimations(),
            provideHttpClient(withInterceptorsFromDi()),
            CookieAcceptService,
            { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
          ],
        }).catch((err) => console.error(err));
      });
  });
