import { TranslateService } from '@ngx-translate/core';
import { Languages, LanguageService } from '@core/services/language.service';
import { lastValueFrom } from 'rxjs';

export const initializeLang = (translateService: TranslateService, languageService: LanguageService) => async () => {
  translateService.addLangs([Languages.ENGLISH, Languages.FRENCH]);
  const lang: Languages = await languageService.getPreferredLanguage();
  languageService.setHTMLProp(lang);
  translateService.setDefaultLang(lang);
  return lastValueFrom(translateService.use(lang));
};
